<ion-content [scrollEvents]="true" [scrollY]="true" class="menu-sidebar transition" color="light-black">
  <ion-list lines="none" style="margin-top: 25px;" class="ion-color-bg-light-black ion-no-padding">
    <ion-menu-toggle auto-hide="false">
      <ion-item class="menu-lat-header-logo">
        <img slot="start" src="assets/imgs/logo/csi/CSI_Logotipo_Color.png" />
        <!-- <ion-label class="menu-lat-header-text">Quantum</ion-label> -->
        <ion-button slot="end" fill="clear" class="close" color="tech-blue" (click)="onClose()">
          <ion-icon slot="icon-only" mode="md" name="close-outline" size="large" style="--ionicon-stroke-width: 20px;">
          </ion-icon>
        </ion-button>
      </ion-item>
    </ion-menu-toggle>

    <ion-item lines="none" class="menu-item" (click)="toggleMenu(false)">
      <!-- <img src="assets/imgs/logo-quantum.png" slot="start" width="36" /> -->
      <!-- <ion-icon slot="start" name="aperture-outline"></ion-icon>
      <p >Quantum</p> -->
      <img class="quantum-logo" slot="start" src="assets/imgs/logo/emblema/Quantum-Emblema-blue.png" />
      <ion-label class="vertical-center-box">
        <img style="width: 80%;" class="" slot="start" src="assets/imgs/logo/wordmark/Quantum-Wordmark-Blue.png" />
      </ion-label>
      <i slot="end" class="arrow" [ngClass]="showQuantumMenu ? 'arrow-down' : 'arrow-right'"></i>
    </ion-item>

    <div class="submenu-list" #submenuElem>

      <ion-menu-toggle auto-hide="true">
        <ion-item lines="none" id="home-item" class="menu-item"
          [ngClass]="activeMenuItem === 'home-item' ? 'active' : null" (click)="goToHome()" *ngIf="showHome">
          <ion-icon slot="start" name="home"></ion-icon>
          <p>{{ 'Menu._Home' | translate }}</p>
        </ion-item>
      </ion-menu-toggle>

      <div *ngIf="multipleDashboardActive; else menuAutoHide">
        <ion-item lines="none" id="dasboard-item" class="menu-item"
          [ngClass]="activeMenuItem === 'dasboard-item' && (!multipleDashboardActive) ? 'active' : null"
          (click)="toggleHomeSubmenu()" *ngIf="showHome">
          <ion-icon slot="start" name="home"></ion-icon>
          <p>{{ 'Menu.Dashboard._Home' | translate }}</p>
          <i *ngIf="multipleDashboardActive" slot="end" class="arrow"
            [ngClass]="showHomeSubmenu ? 'arrow-down' : 'arrow-right'"></i>
        </ion-item>
      </div>

      <ng-template #menuAutoHide>
        <ion-menu-toggle auto-hide="true">
          <ion-item lines="none" id="dasboard-item" class="menu-item"
            [ngClass]="activeMenuItem === 'dasboard-item' && (!multipleDashboardActive) ? 'active' : null"
            (click)="toggleHomeSubmenu()" *ngIf="showHome">
            <ion-icon slot="start" name="home"></ion-icon>
            <p>{{ 'Menu.Dashboard._Home' | translate }}</p>
            <i *ngIf="multipleDashboardActive" slot="end" class="arrow"
              [ngClass]="showHomeSubmenu ? 'arrow-down' : 'arrow-right'"></i>
          </ion-item>
        </ion-menu-toggle>
      </ng-template>

      <div class="home-submenu" #homeSubmenuElem [hidden]="!multipleDashboardActive">
        <div>
          <ul>
            <li *ngIf="dashboardFinancieroActive" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="10" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">

                    <ion-item lines="none" class="menu-item"
                      [ngClass]="activeMenuItem === 'dasboard-item' && dashboardFinancieroActive && currentDashboard === 'financiero' ? 'dashboard-active' : null"
                      (click)="goToDashboardFinanciero()">
                      <ion-icon name="bar-chart-outline" slot="start"></ion-icon>
                      <p>{{ 'Menu.Dashboard._Financiero' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
                <ion-col size="2" class="ion-no-padding ion-color-bg-light-black">
                  <ion-item lines="none" class="menu-item"
                    [ngClass]="activeMenuItem === 'dasboard-item' && dashboardFinancieroActive && currentDashboard === 'financiero' ? 'dashboard-active-rnd' : null"
                    style="padding: 0px;" (click)="selectFavouriteDashboard('financiero')">
                    <ion-icon slot="start" class="fav-icon"
                      [name]="favouriteDashboard === 'financiero' ? 'star' : 'star-outline'">
                    </ion-icon>
                  </ion-item>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="dashboardOperativoActive" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="10" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item lines="none" class="menu-item"
                      [ngClass]="activeMenuItem === 'dasboard-item' && dashboardOperativoActive && currentDashboard === 'operativo' ? 'dashboard-active' : null"
                      (click)="goToDashboardOperativo()">
                      <ion-icon name="bar-chart-outline" slot="start"></ion-icon>
                      <p class="ion-text-nowrap">{{ 'Menu.Dashboard._Operativo' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
                <ion-col size="2" class="ion-no-padding ion-color-bg-light-black">
                  <ion-item lines="none" class="menu-item"
                    [ngClass]="activeMenuItem === 'dasboard-item' && dashboardOperativoActive && currentDashboard === 'operativo' ? 'dashboard-active-rnd' : null"
                    style="padding: 0px;" (click)="selectFavouriteDashboard('operativo')">
                    <ion-icon slot="start" class="fav-icon"
                      [name]="favouriteDashboard === 'operativo' ? 'star' : 'star-outline'">
                    </ion-icon>
                  </ion-item>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="dashboardAMDF" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="10" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item lines="none" class="menu-item"
                      [ngClass]="activeMenuItem === 'dasboard-item' && dashboardAMDF && currentDashboard === 'amdf' ? 'dashboard-active' : null"
                      (click)="goToDashboardAMDF()">
                      <ion-icon name="bar-chart-outline" slot="start"></ion-icon>
                      <p class="ion-text-nowrap">{{ 'Menu.Dashboard._AMDF' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
                <ion-col size="2" class="ion-no-padding ion-color-bg-light-black">
                  <ion-item lines="none" class="menu-item"
                    [ngClass]="activeMenuItem === 'dasboard-item' && dashboardAMDF && currentDashboard === 'amdf' ? 'dashboard-active-rnd' : null"
                    style="padding: 0px;" (click)="selectFavouriteDashboard('amdf')">
                    <ion-icon slot="start" class="fav-icon"
                      [name]="favouriteDashboard === 'amdf' ? 'star' : 'star-outline'">
                    </ion-icon>
                  </ion-item>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="dashboardMazda" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="10" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item lines="none" class="menu-item"
                      [ngClass]="activeMenuItem === 'dasboard-item' && dashboardMazda && currentDashboard === 'mazda' ? 'dashboard-active' : null"
                      (click)="goToDashboardMazda()">
                      <ion-icon name="bar-chart-outline" slot="start"></ion-icon>
                      <p class="ion-text-nowrap">{{ 'Menu.Dashboard._Mazda' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
                <ion-col size="2" class="ion-no-padding ion-color-bg-light-black">
                  <ion-item lines="none" class="menu-item"
                    [ngClass]="activeMenuItem === 'dasboard-item' && dashboardMazda && currentDashboard === 'mazda' ? 'dashboard-active-rnd' : null"
                    style="padding: 0px;" (click)="selectFavouriteDashboard('mazda')">
                    <ion-icon slot="start" class="fav-icon"
                      [name]="favouriteDashboard === 'mazda' ? 'star' : 'star-outline'">
                    </ion-icon>
                  </ion-item>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="dashboardBMW" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="10" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item lines="none" class="menu-item"
                      [ngClass]="activeMenuItem === 'dasboard-item' && dashboardBMW && currentDashboard === 'bmw' ? 'dashboard-active' : null"
                      (click)="goToDashboardBMW()">
                      <ion-icon name="bar-chart-outline" slot="start"></ion-icon>
                      <p class="ion-text-nowrap">{{ 'Menu.Dashboard._BMW' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
                <ion-col size="2" class="ion-no-padding ion-color-bg-light-black">
                  <ion-item lines="none" class="menu-item"
                    [ngClass]="activeMenuItem === 'dasboard-item' && dashboardBMW && currentDashboard === 'bmw' ? 'dashboard-active-rnd' : null"
                    style="padding: 0px;" (click)="selectFavouriteDashboard('bmw')">
                    <ion-icon slot="start" class="fav-icon"
                      [name]="favouriteDashboard === 'bmw' ? 'star' : 'star-outline'">
                    </ion-icon>
                  </ion-item>
                </ion-col>
              </ion-row>
            </li>
          </ul>
        </div>
      </div>

      <ion-item lines="none" id="dashboard-bsc-item" class="menu-item" (click)="toggleDashboardBSCSubmenu()"
        *ngIf="showDashboardBSC">
        <ion-icon slot="start" name="file-tray-full-outline"></ion-icon>
        <p>{{ 'Menu.BSC._Home' | translate }}</p>
        <i slot="end" class="arrow" [ngClass]="showDashboardBSCSubmenu ? 'arrow-down' : 'arrow-right'"></i>
      </ion-item>

      <div class="bsc-submenu" #dashboardBSCSubmenuElem>
        <div>
          <ul>
            <li *ngIf="dashboardBSC_CPVActive" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="12" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item lines="none" class="menu-item"
                      [ngClass]="activeMenuItem === 'dashboard-bsc-item' && dashboardBSC_CPVActive && (currentDashboardBSC === 'cpv') ? 'active' : null"
                      (click)="goToDashboardBSCCPV()">
                      <ion-icon name="file-tray-full-outline" slot="start"></ion-icon>
                      <p class="ion-text-nowrap">{{ 'Menu.BSC._CPV' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="dashboardBSC_CSIActive" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="12" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item lines="none" class="menu-item"
                      [ngClass]="activeMenuItem === 'dashboard-bsc-item' && dashboardBSC_CSIActive && (currentDashboardBSC === 'csi') ? 'active' : null"
                      (click)="goToDashboardBSCCSI()">
                      <ion-icon name="file-tray-full-outline" slot="start"></ion-icon>
                      <p class="ion-text-nowrap">{{ 'Menu.BSC._CSI' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="dashboardBSC_CSIDActive" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="12" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item lines="none" class="menu-item"
                      [ngClass]="activeMenuItem === 'dashboard-bsc-item' && dashboardBSC_CSIDActive && (currentDashboardBSC === 'csid') ? 'active' : null"
                      (click)="goToDashboardBSCCSID()">
                      <ion-icon name="file-tray-full-outline" slot="start"></ion-icon>
                      <p class="ion-text-nowrap">{{ 'Menu.BSC._CSID' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="dashboardBSC_CSIXActive" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="12" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item lines="none" class="menu-item"
                      [ngClass]="activeMenuItem === 'dashboard-bsc-item' && dashboardBSC_CSIXActive && (currentDashboardBSC === 'csix') ? 'active' : null"
                      (click)="goToDashboardBSCCSIX()">
                      <ion-icon name="file-tray-full-outline" slot="start"></ion-icon>
                      <p class="ion-text-nowrap">{{ 'Menu.BSC._CSIX' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="dashboardBSC_SunusActive" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="12" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item lines="none" class="menu-item"
                      [ngClass]="activeMenuItem === 'dashboard-bsc-item' && dashboardBSC_SunusActive && (currentDashboardBSC === 'sunus') ? 'active' : null"
                      (click)="goToDashboardBSCSunus()">
                      <ion-icon name="file-tray-full-outline" slot="start"></ion-icon>
                      <p class="ion-text-nowrap">{{ 'Menu.BSC._SUNUS' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
              </ion-row>
            </li>
          </ul>
        </div>
      </div>

      <ion-menu-toggle auto-hide="true">
        <ion-item lines="none" id="investor-relations-item" class="menu-item"
          [ngClass]="activeMenuItem === 'investor-relations-item' ? 'active' : null"
          (click)="goToInvestorRelationsPage()" *ngIf="showInvestorRelations">
          <ion-icon slot="start" name="folder-outline"></ion-icon>
          <p>{{ 'Menu._Investor' | translate }}</p>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle auto-hide="true">
        <ion-item lines="none" id="tracking-map-item" class="menu-item"
          [ngClass]="activeMenuItem === 'tracking-map-item' ? 'active' : null" (click)="goToTrackingMap()"
          *ngIf="showTrackingMap">
          <ion-icon slot="start" name="location"></ion-icon>
          <p>{{ 'Menu._Rastreo' | translate }}</p>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle auto-hide="true">
        <ion-item lines="none" id="travel-list-item" class="menu-item"
          [ngClass]="activeMenuItem === 'travel-list-item' ? 'active' : null" (click)="goToTravelList()"
          *ngIf="showTravelList">
          <ion-icon slot="start" name="list"></ion-icon>
          <p>{{ 'Menu._Viajes' | translate }}</p>
        </ion-item>
      </ion-menu-toggle>

      <!-- <ion-menu-toggle auto-hide="true">
        <ion-item id="vessel-travel-list-item" class="menu-item"
          [ngClass]="activeMenuItem === 'vessel-travel-list-item' ? 'active' : null" (click)="goToVesselTravelList()"
          *ngIf="showVesselTravelList">
          <ion-icon slot="start" name="list"></ion-icon>
          <p>{{ 'Menu._Viajes_Buque' | translate }}</p>
        </ion-item>
      </ion-menu-toggle> -->

      <ion-menu-toggle auto-hide="true">
        <ion-item lines="none" id="ratings-item" class="menu-item"
          [ngClass]="activeMenuItem === 'ratings-item' ? 'active' : null" (click)="goToScore()" *ngIf="showScore">
          <ion-icon slot="start" src="assets/imgs/icons/star.svg"></ion-icon>
          <p>{{ 'Menu._Ratings' | translate }}</p>
        </ion-item>
      </ion-menu-toggle>

      <!-- 
      <ion-item (click)="gotToConsultaCD()" *ngIf="showConsultaCD">
        <ion-icon slot="start" style="width:30px; height:20px; margin-bottom: 5% !important;">
          <img src="assets/imgs/factory_white_transparent.png" style="margin-bottom: 10px;">
        </ion-icon>
        Consulta CD
      </ion-item> 
      -->

      <ion-menu-toggle auto-hide="true">
        <ion-item lines="none" id="exchange-item" class="menu-item"
          [ngClass]="activeMenuItem === 'exchange-item' ? 'active' : null" (click)="goToExchange()"
          *ngIf="showExchange">
          <ion-icon name="repeat-sharp" slot="start"></ion-icon>
          <p>{{ 'Menu._Intercambios' | translate }}</p>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle auto-hide="true">
        <ion-item lines="none" id="shipment-request-item" class="menu-item"
          [ngClass]="activeMenuItem === 'shipment-request-item' ? 'active' : null" (click)="goToShipmentRequest()"
          *ngIf="showShipmentRequest">
          <ion-icon name="car" slot="start"></ion-icon>
          <p>{{ 'Menu.Mobile._Embarque' | translate }}</p>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle auto-hide="true">
        <ion-item lines="none" id="report-list-item" class="menu-item"
          [ngClass]="activeMenuItem === 'report-list-item' ? 'active' : null" (click)="goToReports()"
          *ngIf="showReport">
          <ion-icon slot="start" name="document"></ion-icon>
          <p>{{ 'Menu._Reportes' | translate }}</p>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle auto-hide="true">
        <ion-item lines="none" id="consulta-cd-item" class="menu-item"
          [ngClass]="activeMenuItem === 'consulta-cd-item' ? 'active' : null" (click)="goToConsultBySiteMexico()"
          *ngIf="showConsultBySiteMexico">
          <ion-icon slot="start" name="business-outline"></ion-icon>
          <p>{{ 'Menu._Consulta_CD' | translate }}</p>
        </ion-item>
      </ion-menu-toggle>

      <!-- <ion-item (click)="goToConsultByDealer()" *ngIf="showConsultByDealer">
        <ion-icon slot="start" name="podium"></ion-icon>
        Consulta Distribuidor
      </ion-item> -->

      <ion-menu-toggle auto-hide="true">
        <ion-item lines="none" id="consulta-vin-item" class="menu-item"
          [ngClass]="activeMenuItem === 'consulta-vin-item' ? 'active' : null" (click)="goToMultipleVinConsult()"
          *ngIf="showMultipleVinConsult">
          <ion-icon slot="start" name="search"></ion-icon>
          <p>{{ 'Menu._Consulta_VIN' | translate }}</p>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle auto-hide="true">
        <ion-item lines="none" id="assets-manager-item" class="menu-item"
          [ngClass]="activeMenuItem === 'assets-manager-item' ? 'active' : null" (click)="goToAssetsManagerPage()"
          *ngIf="showAssetsManager">
          <ion-icon slot="start" name="bag-outline"></ion-icon>
          <p>{{ 'Menu._Assets' | translate }}</p>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle auto-hide="true">
        <ion-item lines="none" id="matriz-estatus-item" class="menu-item"
          [ngClass]="activeMenuItem === 'matriz-estatus-item' ? 'active' : null" (click)="goToMatrizEstatusEquipo()"
          *ngIf="showMatrizEstatusEquipo">
          <ion-icon slot="start" name="bus"></ion-icon>
          <p>{{ 'Menu.Mobile._Matriz' | translate }}</p>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle auto-hide="true">
        <ion-item lines="none" id="programacion-carga-item" class="menu-item"
          [ngClass]="activeMenuItem === 'programacion-carga-item' ? 'active' : null" (click)="goToProgramacionCarga()"
          *ngIf="showProgramacionCarga">
          <ion-icon slot="start" name="compass"></ion-icon>
          <p>{{ 'Menu.Mobile._Posicionamiento' | translate }}</p>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle auto-hide="true">
        <ion-item lines="none" id="alarmas-item" class="menu-item"
          [ngClass]="activeMenuItem === 'alarmas-item' ? 'active' : null" (click)="goToAlertasPage()"
          *ngIf="showAlertasPage">
          <ion-icon slot="start" name="alert"></ion-icon>
          <p>{{ 'Menu._Alertas' | translate }}</p>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle auto-hide="true">
        <ion-item lines="none" id="consulta-tractor-item" class="menu-item"
          [ngClass]="activeMenuItem === 'consulta-tractor-item' ? 'active' : null" (click)="goToConsultaDeTractor()"
          *ngIf="showConsultaDeTractor">
          <ion-icon slot="start" name="search"></ion-icon>
          <p>{{ 'Menu._Tractor' | translate }}</p>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle auto-hide="true">
        <ion-item lines="none" id="configuracion-item" class="menu-item"
          [ngClass]="activeMenuItem === 'rate-trip-item' ? 'active' : null" (click)="goToRateTrip()"
          *ngIf="showRateTrip">
          <ion-icon slot="start" name="star"></ion-icon>
          <p>{{ 'Titulo._Calificar_Viaje' | translate }}</p>
        </ion-item>
      </ion-menu-toggle>
      
      <ion-menu-toggle auto-hide="true">
        <ion-item lines="none" id="configuracion-item" class="menu-item"
          [ngClass]="activeMenuItem === 'permisos-item' ? 'active' : null" (click)="goToUserPermissions()"
          *ngIf="showPermissions">
          <ion-icon slot="start" name="people-circle"></ion-icon>
          <p>{{ 'Menu._Permisos' | translate }}</p>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle auto-hide="true">
        <ion-item lines="none" id="configuracion-item" class="menu-item"
          [ngClass]="activeMenuItem === 'configuracion-item' ? 'active' : null" (click)="goToConfig()"
          *ngIf="showConfig">
          <ion-icon slot="start" name="settings"></ion-icon>
          <p>{{ 'Menu._Configuración' | translate }}</p>
        </ion-item>
      </ion-menu-toggle>

      <ion-item lines="none" id="dashboard-bsc-item" class="menu-item" (click)="toggleShowMoreSubmenu()"
        *ngIf="showMoreMenu">
        <ion-icon slot="start" name="ellipsis-vertical-outline"></ion-icon>
        <p *ngIf="!showMoreMenuDetails">{{ 'Menu._Show_More' | translate }}</p>
        <p *ngIf="showMoreMenuDetails">{{ 'Menu._Show_Less' | translate }}</p>
        <i slot="end" class="arrow" [ngClass]="showMoreMenuDetails ? 'arrow-down' : 'arrow-right'"></i>
      </ion-item>

      <div class="more-submenu" #moreMenuSubmenuElem>
        <div>
          <ul>
            <li *ngIf="showInvestorRelations_Collapsible" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="12" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item class="menu-item" lines="none"
                      [ngClass]="activeMenuItem === 'investor-relations-item' ? 'active' : null"
                      (click)="goToInvestorRelationsPage()">
                      <ion-icon slot="start" name="folder-outline"></ion-icon>
                      <p>{{ 'Menu._Investor' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="showTrackingMap_Collapsible" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="12" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item class="menu-item" lines="none"
                      [ngClass]="activeMenuItem === 'tracking-map-item' ? 'active' : null" (click)="goToTrackingMap()">
                      <ion-icon slot="start" name="location"></ion-icon>
                      <p>{{ 'Menu._Rastreo' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="showTravelList_Collapsible" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="12" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item class="menu-item" lines="none"
                      [ngClass]="activeMenuItem === 'travel-list-item' ? 'active' : null" (click)="goToTravelList()">
                      <ion-icon slot="start" name="list"></ion-icon>
                      <p>{{ 'Menu._Viajes' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="showScore_Collapsible" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="12" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item class="menu-item" lines="none"
                      [ngClass]="activeMenuItem === 'ratings-item' ? 'active' : null" (click)="goToScore()">
                      <ion-icon slot="start" src="assets/imgs/icons/star.svg"></ion-icon>
                      <p>{{ 'Menu._Ratings' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="showExchange_Collapsible" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="12" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item class="menu-item" lines="none"
                      [ngClass]="activeMenuItem === 'exchange-item' ? 'active' : null" (click)="goToExchange()">
                      <ion-icon name="repeat-sharp" slot="start"></ion-icon>
                      <p>{{ 'Menu._Intercambios' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="showShipmentRequest_Collapsible" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="12" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item class="menu-item" lines="none"
                      [ngClass]="activeMenuItem === 'shipment-request-item' ? 'active' : null"
                      (click)="goToShipmentRequest()">
                      <ion-icon name="car" slot="start"></ion-icon>
                      <p>{{ 'Menu._Embarque' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="showReport_Collapsible" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="12" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item class="menu-item" lines="none"
                      [ngClass]="activeMenuItem === 'report-list-item' ? 'active' : null" (click)="goToReports()">
                      <ion-icon slot="start" name="document"></ion-icon>
                      <p>{{ 'Menu._Reportes' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="showConsultBySiteMexico_Collapsible" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="12" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item class="menu-item" lines="none"
                      [ngClass]="activeMenuItem === 'consulta-cd-item' ? 'active' : null"
                      (click)="goToConsultBySiteMexico()">
                      <ion-icon slot="start" name="business-outline"></ion-icon>
                      <p>{{ 'Menu._Consulta_CD' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="showMultipleVinConsult_Collapsible" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="12" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item class="menu-item" lines="none"
                      [ngClass]="activeMenuItem === 'consulta-vin-item' ? 'active' : null"
                      (click)="goToMultipleVinConsult()">
                      <ion-icon slot="start" name="search"></ion-icon>
                      <p>{{ 'Menu._Consulta_VIN' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="showAssetsManager_Collapsible" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="12" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item class="menu-item" lines="none"
                      [ngClass]="activeMenuItem === 'assets-manager-item' ? 'active' : null"
                      (click)="goToAssetsManagerPage()">
                      <ion-icon slot="start" name="bag-outline"></ion-icon>
                      <p>{{ 'Menu._Assets' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="showMatrizEstatusEquipo_Collapsible" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="12" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item class="menu-item" lines="none"
                      [ngClass]="activeMenuItem === 'matriz-estatus-item' ? 'active' : null"
                      (click)="goToMatrizEstatusEquipo()">
                      <ion-icon slot="start" name="bus"></ion-icon>
                      <p>{{ 'Menu._Matriz' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="showProgramacionCarga_Collapsible" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="12" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item class="menu-item" lines="none"
                      [ngClass]="activeMenuItem === 'programacion-carga-item' ? 'active' : null"
                      (click)="goToProgramacionCarga()">
                      <ion-icon slot="start" name="compass"></ion-icon>
                      <p>{{ 'Menu._Posicionamiento' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="showAlertasPage_Collapsible" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="12" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item class="menu-item" lines="none"
                      [ngClass]="activeMenuItem === 'alarmas-item' ? 'active' : null" (click)="goToAlertasPage()">
                      <ion-icon slot="start" name="alert"></ion-icon>
                      <p>{{ 'Menu._Alertas' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="showConsultaDeTractor_Collapsible" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="12" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">
                    <ion-item class="menu-item" lines="none"
                      [ngClass]="activeMenuItem === 'consulta-tractor-item' ? 'active' : null"
                      (click)="goToConsultaDeTractor()">
                      <ion-icon slot="start" name="search"></ion-icon>
                      <p>{{ 'Menu._Tractor' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="showRateTrip_Collapsible" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="12" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">

                    <ion-item class="menu-item" lines="none"
                      [ngClass]="activeMenuItem === 'rate-trip-item' ? 'active' : null" (click)="goToRateTrip()">
                      <ion-icon slot="start" name="star"></ion-icon>
                      <p>{{ 'Titulo._Calificar_Viaje' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
              </ion-row>
            </li>
            <li *ngIf="showPermissions_Collapsible" class="ion-color-bg-light-black">
              <ion-row>
                <ion-col size="12" class="ion-no-padding">
                  <ion-menu-toggle auto-hide="true">

                    <ion-item class="menu-item" lines="none"
                      [ngClass]="activeMenuItem === 'people-circle' ? 'active' : null" (click)="goToUserPermissions()">
                      <ion-icon slot="start" name="star"></ion-icon>
                      <p>{{ 'Menu._Permisos' | translate }}</p>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-col>
              </ion-row>
            </li>

          </ul>
        </div>
      </div>
    </div>

    <!-- Sub Menu de Quantum Drive -->
    <ion-menu-toggle *ngIf="showDrive" auto-hide="true">
      <ion-item lines="none" id="configuracion-item" class="menu-item" (click)="goToWebFrame()">
        <img class="quantum-logo" slot="start"
          src="assets/imgs/logo/wordmark/Quantum-Drive/Emblema/Quantum-Drive-Emblema-white.png" />
        <p class="lb-logo-wordmark">Drive</p>
      </ion-item>
    </ion-menu-toggle>

    <!-- Sub Menu de Quantum Fleet -->
    <ion-menu-toggle *ngIf="showFleet" auto-hide="true">
      <ion-item lines="none" id="configuracion-item" class="menu-item" (click)="goToWebFrame()">
        <img class="quantum-logo" slot="start"
          src="assets/imgs/logo/wordmark/Quantum-Fleet/Emblema/Quantum-Fleet-Emblema-white.png" />
        <p class="lb-logo-wordmark">Fleet</p>
      </ion-item>
    </ion-menu-toggle>

    <!-- Sub Menu de Quantum Ontrack -->
    <ion-menu-toggle *ngIf="showOntrack" auto-hide="true">
      <ion-item lines="none" id="configuracion-item" class="menu-item" (click)="goToWebFrame()">
        <img class="quantum-logo" slot="start"
          src="assets/imgs/logo/wordmark/Quantum-Ontrack/Emblema/Quantum-Ontrack-Emblema-white.png" />
        <p class="lb-logo-wordmark">Ontrack</p>
      </ion-item>
    </ion-menu-toggle>

    <!-- Sub Menu de Quantum Yard -->
    <ion-menu-toggle *ngIf="showYard" auto-hide="true">
      <ion-item lines="none" id="configuracion-item" class="menu-item" (click)="goToWebFrame()">
        <img class="quantum-logo" slot="start"
          src="assets/imgs/logo/wordmark/Quantum-Yard/Emblema/Quantum-Yard-Emblema-white.png" />
        <p class="lb-logo-wordmark">Yard</p>
      </ion-item>
    </ion-menu-toggle>

    <ng-container *ngFor="let civarItem of civarMenu; let idx = index;">
      <ion-item lines="none" class="menu-item" (click)="toggleMenu(true, idx)">
        <ion-icon slot="start" [name]="getIconNameCivarMenu(civarItem.Cve_Desc_Menu)"></ion-icon>
        <p>{{civarItem.Cve_Desc_Menu}}</p>
        <i slot="end" class="arrow" [ngClass]="showCivarMenu[idx] ? 'arrow-down' : 'arrow-right'"></i>
      </ion-item>
      <div class="civar-menu" #civarElem>
        <my-menu [items]="civarItem.OpcionMenu || []" [level]="1" (onSubmenuOpen)="resizeContainer($event, idx);">
        </my-menu>
      </div>
    </ng-container>

    <ion-menu-toggle auto-hide="true">
      <ion-item lines="none" class="menu-item" (click)="logout()">
        <ion-icon slot="start" name="log-out-outline"></ion-icon>
        <p>{{ 'Menu._Log_Out' | translate }}</p>
      </ion-item>
      <ion-item color="light-black" lines="none" style="height: 70px !important; --padding-bottom:70px;"></ion-item>
    </ion-menu-toggle>
  </ion-list>
</ion-content>